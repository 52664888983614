export const environment = {
    apiURL: "https://backend.saladproject.org",
    production: true,
    firebase: {
        apiKey: "AIzaSyBkSP50ZRNoq6xYcJFnmJuSCFioEbEsS2o",
        authDomain: "salad-main.firebaseapp.com",
        projectId: "salad-main",
        storageBucket: "salad-main.appspot.com",
        messagingSenderId: "250642205047",
        appId: "1:250642205047:web:1dfd574b4a2bfa80f27972",
        measurementId: "G-G7P03STFC1"
    }
};